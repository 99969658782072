// External Dependencies
import { kebabCase } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Link, graphql } from 'gatsby';

// Internal Dependencies
import Layout from '../components/layout';
import Meta from '../components/Meta';
import prestoGradientLogo from '../assets/images/logo-gradient.svg';

// Local Variables
const propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        author: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }),
      html: PropTypes.string,
    }),
  }).isRequired,
  pageContext: PropTypes.shape({
    next: PropTypes.shape({
      fields: PropTypes.shape({
        slug: PropTypes.slug,
      }),
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
      }).isRequired,
    }),
    previous: PropTypes.shape({
      fields: PropTypes.shape({
        slug: PropTypes.slug,
      }),
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
      }).isRequired,
    }),
  }),
};

// Component Definition

// eslint-disable-next-line react/prefer-stateless-function
class ReleaseNotesPostTemplate extends React.Component {
  render() {
    const releaseNotesPost = this.props.data.markdownRemark;
    const { previous, next } = this.props.pageContext;

    const {
      frontmatter: {
        author,
        date,
        title,
      },
      html,
    } = releaseNotesPost;

    return (
      <Layout variant="releases">
        <Meta title={`Release Notes — ${title} | Presto`} />

        <div
          aria-hidden="true"
          className="release-divider divider-top"
        />

        <main id="releases">
          <section className="releases-article-wrapper">
            <article id={kebabCase(title)}>
              <div className="release-content">
                <div className="release-date">{date}</div>
                <header>
                  <h2>{title}</h2>

                  <div className="author-block">
                    <img
                      alt="Presto Assistant gradient logo."
                      className="author-block-pic-logo"
                      src={prestoGradientLogo}
                    />
                    <p>{author}</p>
                  </div>

                  <p>
                    {date}
                  </p>
                </header>
                <div
                  className="release-body"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: html }}
                />
                <hr />

                <footer>
                  <ul className={!next ? 'only-next-post' : ''}>
                    {next && (
                      <li className="previous-post">
                        <p>Previous post</p>
                        <Link
                          className="blog-post-link"
                          rel="next"
                          to={`/releases${next.fields.slug}`}
                        >
                          ←{next.frontmatter.title}&nbsp;&nbsp;
                        </Link>
                      </li>
                    )}

                    {previous && (
                      <li className="next-post">
                        <p>Next post</p>
                        <Link
                          className="blog-post-link"
                          rel="prev"
                          to={`/releases${previous.fields.slug}`}
                        >
                          &nbsp;&nbsp;{previous.frontmatter.title}→
                        </Link>
                      </li>
                    )}
                  </ul>
                </footer>
              </div>
            </article>
          </section>
        </main>
      </Layout>
    );
  }
}

ReleaseNotesPostTemplate.propTypes = propTypes;

export default ReleaseNotesPostTemplate;

export const pageQuery = graphql`
  query ReleaseNotesByDate($slug: String!) {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
    markdownRemark(
      fields: {
        collection: {
          eq: "release"
        }
        slug: {
          eq: $slug
        }
      }
    ) {
    id
    excerpt
    frontmatter {
      author
      title
      date(formatString: "MMMM DD, YYYY")
    }
    html
  }
  }
`;
